<div class="box1">
  <div class="row g-0"></div>
  <div class="row mt-3">
    <div class="col-sm-12 col-lg-6">
      <div class="row">
        <div class="col-3 col-md-2">
          <img class="img-fluid img--wallet" src="assets/img/wallet-1.png" />
        </div>
        <div
          class="col-8 available--points"
          [class]="lang.isRTL ? 'rtl-available--points' : ''"
        >
          <div class="text2">
            <app-spinner *ngIf="loading"></app-spinner>
            {{ flexDollarsAvailable | number }}
          </div>
          <div class="point--details">
            <!-- <img class="ms-2 img--reload" src="assets/img/reload.png" width="10.5px" height="10.5px"
            /> -->
            &nbsp;&nbsp;
            <div class="text__desc-detail">
              {{ "common.pointsAvailableForRedemption" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-sm-12"
      [ngClass]="pointHistoryShowPointSideBySide ? 'col-lg-6' : ''"
    >
      <div class="row g-3">
        <div
          class="box--img-wrapper"
          [ngClass]="pointsClass"
          (click)="updateTransactions('earned', 'all')"
        >
          <div class="d-flex flex-column box__detail2 px-2 py-3">
            <div class="text__point-detail">
              {{ flexDollarsEarned | number }}
            </div>
            <div class="text__desc-detail mt-2">
              {{ "common.totalPointsEarned" | translate }}
            </div>
          </div>
        </div>
        <div
          class="box--img-wrapper"
          [ngClass]="pointsClass"
          (click)="updateTransactions('redeemed', 'all')"
        >
          <div class="d-flex flex-column box__detail2 px-2 py-3">
            <div class="text__point-detail">
              {{ flexDollarsRedeemed | number }}
            </div>
            <div class="text__desc-detail mt-2">
              {{ "common.totalPointsRedeemed" | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="!!expiryPoint"
          class="box--img-wrapper"
          [ngClass]="pointsClass"
          (click)="updateTransactions('expiring', 'all')"
        >
          <div class="d-flex flex-column box__detail3 p-3">
            <div class="text__point-detail">
              {{ pointsExpiringThisMonth.points | number }}
            </div>
            <div class="text__desc-detail text__expiry-points mt-2">
              {{
                "common.totalPointsExpiring"
                  | translate
                    : {
                        year: showYearlyExpiringPoints
                          ? (pointsExpiringThisMonth.expiry_date | date: "yyyy")
                          : (pointsExpiringThisMonth.expiry_date
                            | date
                              : "dd
          MMM yyyy")
                      }
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
